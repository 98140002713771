import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import React, { useState, useEffect } from "react";
import { styled } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAndroid, faApple, faChrome, faEdge, faFirefoxBrowser, faLinux, faOpera, faSafari, faUbuntu, faWindows } from "@fortawesome/free-brands-svg-icons";
import { faDesktop, faInfoCircle, faMapLocation, cation, faSatellite, faShieldHalved, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import useCoordinates from "../../Hooks/useCoordinates";
import ToggleSwitch from "../UI/buttons/ToggleSwitch";

export default function SomeMap() {
    const [oSName, setOSName] = useState("Unknown");
    const [oSIcon, setOSIcon] = useState(faTriangleExclamation);
    useEffect(() => {
        if (window.navigator.userAgent.indexOf("Win") !== -1) {
            setOSName("Windows");
            setOSIcon(faWindows);
        } else if (window.navigator.userAgent.indexOf("Mac") !== -1) {
            setOSName("MacOS");
            setOSIcon(faApple);
        } else if (window.navigator.userAgent.indexOf("android") !== -1) {
            setOSName("Android");
            setOSIcon(faAndroid);
        } else if (window.navigator.userAgent.indexOf("Linux x86_64") !== -1) {
            setOSName("Ubuntu");
            setOSIcon(faUbuntu);
        } else if (window.navigator.userAgent.indexOf("X11") !== -1) {
            setOSName("UNIX");
            setOSIcon(faTriangleExclamation);
        } else if (window.navigator.userAgent.indexOf("Linux") !== -1) {
            setOSName("Linux");
            setOSIcon(faLinux);
        } else {
            setOSName("unknown");
            setOSIcon(faTriangleExclamation);
        }
    }, []);

    const [browserName, setBrowserName] = useState("Unknown");
    const [browserIcon, setBrowserIcon] = useState(faTriangleExclamation);

    useEffect(() => {
        if (window.navigator.userAgent.indexOf("Chrome") !== -1) {
            setBrowserName("Chrome");
            setBrowserIcon(faChrome);
        } else if (window.navigator.userAgent.indexOf("Safari") !== -1) {
            setBrowserName("Safari");
            setBrowserIcon(faSafari);
        } else if (window.navigator.userAgent.indexOf("Firefox") !== -1) {
            setBrowserName("FireFox");
            setBrowserIcon(faFirefoxBrowser);
        } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
            setBrowserName("Opera");
            setBrowserIcon(faOpera);
        } else if (window.navigator.userAgent.indexOf("Edg") !== -1) {
            setBrowserName("Edge");
            setBrowserIcon(faEdge);
        } else {
            setBrowserName("unknown");
            setBrowserIcon(faTriangleExclamation);
        }
    }, []);

    const [adBlockEnabled, setAdBlockEnabled] = useState(false);
    useEffect(() => {
        const googleAdUrl = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";

        const checkAdBlock = async () => {
            try {
                await fetch(new Request(googleAdUrl));
            } catch (e) {
                setAdBlockEnabled(true);
            }
        };

        checkAdBlock();
    }, []);

    const [ipAddress, setIpAddress] = useState("Connecting...");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("https://nodejs-menu.vercel.app/info");
                setIpAddress(response.data);
            } catch (error) {
                console.error("Error fetching IP address:", error);
            }
        };

        fetchData();
    }, []);

    const [showUserAgentBox, setShowUserAgentBox] = useState(false);

    const { latitude, longitude } = useCoordinates();
    const [position, setPosition] = useState([latitude, longitude]);
    const [showMap, setShowMap] = useState(true);

    useEffect(() => {
        setPosition([latitude, longitude]);
        setInterval(setShowMap(false), 5000);
    }, [latitude, longitude]);

    const customIcon = new Icon({
        // iconUrl: "https://cdn-icons-png.flaticon.com/512/447/447031.png",
        iconUrl: require("../../Assets/marker.png"),
        iconSize: [30, 30], // size of the icon
    });

    return (
        <>
            <SpanSpread>
                <h2 id="DeviceInfo">
                    Device Info <Beta>beta</Beta>
                </h2>
                <SpanRow>
                    <FontAwesomeIcon icon={faMapLocation} title="this will hide the map & ip" />
                    <p>Hide Map</p>
                    <ToggleSwitch ToggleDisabled={false} isToggeld={showMap} onToggle={() => setShowMap((prevState) => !prevState)} />
                </SpanRow>
            </SpanSpread>
            <StyledArticle onClick={() => setShowUserAgentBox((prevState) => !prevState)}>
                <SpanGrid>
                    <SpanCollom>
                        <FontAwesomeIcon icon={faDesktop} size="3x" />
                        <h3>Desktop</h3>
                    </SpanCollom>
                    <SpanCollom>
                        <SpanRow>
                            <FontAwesomeIcon icon={oSIcon} />
                            <h4>{oSName}</h4>
                        </SpanRow>
                        <SpanRow>
                            <FontAwesomeIcon icon={browserIcon} />
                            <SpanRow>
                                <h4>{browserName}</h4>
                                <p>{}</p>
                            </SpanRow>
                        </SpanRow>
                        <SpanRow>
                            <FontAwesomeIcon icon={faShieldHalved} />
                            <SpanRow>
                                <h4>Adblocker</h4>
                                {adBlockEnabled ? <p>True</p> : <p>False</p>}
                            </SpanRow>
                        </SpanRow>
                        <SpanRow>
                            <FontAwesomeIcon icon={faSatellite} />
                            <SpanRow>
                                <h4>IP </h4>
                                {showMap === true ? <p>privacy</p> : <CopyMe onClick={() => navigator.clipboard.writeText(ipAddress.ip)}>{ipAddress.ip}</CopyMe>}
                            </SpanRow>
                        </SpanRow>
                    </SpanCollom>
                    {showMap === false ? (
                        <StyledMap>
                            <MapContainer zoomControl={false} attributionControl={false} center={position} zoom={14} scrollWheelZoom={false} doubleClickZoom={false} dragging={false} keyboard={false}>
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker position={position} icon={customIcon}>
                                    <Popup>Your Location ?</Popup>
                                </Marker>
                            </MapContainer>
                        </StyledMap>
                    ) : (
                        <></>
                    )}
                </SpanGrid>
                {showUserAgentBox === true ? (
                    <>
                        <br />
                        <SpanCenter>
                            <SpanRow>
                                <h4>latitude: </h4>
                                <p>{latitude}</p>
                                <h4>longitude: </h4>
                                <p>{longitude}</p>
                            </SpanRow>
                        </SpanCenter>
                        <SpanCenter>
                            <FontAwesomeIcon icon={faInfoCircle} title="This is for the more advanced user" />
                            <SpanRow>
                                <h4>{window.navigator.userAgent}</h4>
                            </SpanRow>
                        </SpanCenter>
                    </>
                ) : (
                    <></>
                )}
            </StyledArticle>
        </>
    );
}

const StyledArticle = styled.article`
    padding: 1rem;
    background-color: var(--wrapperColor);
    border-radius: var(--borderRadius);
    cursor: pointer;
    section {
        display: grid;
        align-content: space-between;
        grid-template-columns:
            minmax(150px, 1.75fr)
            auto;
        width: 100%;
    }
`;

const SpanRow = styled.span`
    display: flex;
    gap: 0.5rem;
    align-items: center;
    z-index: 3;
`;

const SpanCollom = styled.span`
    display: flex;
    flex-direction: column;
`;

const SpanGrid = styled.span`
    display: grid;
    grid-template-columns: 1fr 1fr 35%;
    justify-items: center;
    align-items: center;
`;

const SpanCenter = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
`;

const Beta = styled.small`
    font-size: 0.9rem;
    color: var(--activeColor);
`;

const StyledMap = styled.div`
    height: 130px;
    width: 400px;
    .leaflet-container {
        height: calc(100% + 2rem);
        width: 100%;
        /* height: 260px;
        width: 38vw; */
        margin: -1rem;
        border-radius: 0 var(--borderRadius) var(--borderRadius) 0;
        z-index: 1;
    }
    .leaflet-tile {
        /* filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7); */
        filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(80%);
    }
    position: relative;
    &:before {
        margin: -1rem;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        border-top: calc(130px + 2rem) solid var(--wrapperColor);
        border-right: 150px solid transparent;
        width: 0;
        z-index: 2;
    }
`;

const SpanSpread = styled.span`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const CopyMe = styled.p`
    cursor: context-menu;
    &:hover {
        color: var(--activeColor);
    }
`;
